import ConnectionStatus from "./ConnectionStatus";
import { ISocketItem } from "./ISocketItem";

export enum SocketEvents {
  HEADER = "Header",
  LEADERBOARD = "Leaderboard",
  DASHBOARD = "Dashboard",
}

export const initSockets = (defaultPort: number) => {
  return [
    { port: defaultPort, name: SocketEvents.HEADER, connectionStatus: ConnectionStatus.CLOSED },
    { port: defaultPort + 1, name: SocketEvents.LEADERBOARD, connectionStatus: ConnectionStatus.CLOSED },
    { port: defaultPort + 2, name: SocketEvents.DASHBOARD, connectionStatus: ConnectionStatus.CLOSED },
  ] as ISocketItem[];
};
