import { useRef, useEffect, useLayoutEffect, useState } from "react";
import "styles/components/trackmap.css";
import Map from "components/TrackMap/Map";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store/hooks";
import ReconnectingWebSocket from "reconnecting-websocket";
import { IDriver } from "models/IDriver";
import { setNotification } from "store/reducers/notificationSlice";
import NotificationStatus from "models/NotificationStatus";
import { SocketEvents } from "models/Sockets";
import Driver from "components/Leaderboard/Driver";
import { Skeleton } from "@mui/material";
import { animated, useSprings } from "react-spring";
//import { map } from "./map";
//import { SettingsIcon } from "helpers/icons";
//import { CogIcon } from "@heroicons/react/outline";
import { setLeaderboardDrivers } from "store/reducers/socketSlice";
import md5 from "js-md5";
const TrackMap = () => {
  const { ip, sockets, leaderboardDriver, trackName } = useAppSelector((state) => state.socket);
  const [ws, setWs] = useState<ReconnectingWebSocket | null>(null);
  const [canvasHeight, setCanvasHeight] = useState(0);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [selectedDrivers, setSelectedDrivers] = useState<number[]>([]);
  const [animatedDrivers, setAnimatedDrivers] = useState<any[]>([]);
  const [drivers, setDrivers] = useState<IDriver[]>([]);
  const [initialDriversArray, setInitialDriversArray] = useState<IDriver[]>([]);
  const [divider, setDivider] = useState(0);
  const [coordinates, setCoordinates] = useState();
  const [minX, setMinX] = useState(0);
  const [minY, setMinY] = useState(0);
  const [maxX, setMaxX] = useState(0);
  const [maxY, setMaxY] = useState(0);
  const dispatch = useDispatch();
  const div = useRef<HTMLDivElement>(null);

  const styles = useSprings(
    animatedDrivers.length,
    animatedDrivers.map((driver) => ({
      transform: `translate3d(${driver.x ? driver.x : 0}px, ${driver.y ? driver.y : 0}px, 0)`,
      config: { duration: 1000, mass: 1, tension: 210, friction: 20, stiffness: 20, dumping: 0.8 },
      content: driver.place
    }))
  );

  useLayoutEffect(() => {
    function updateSize() {
      setDivider(1);  // Setting divider to something just to trigger redrawing the trackmap
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    let newDrivers: any[] = [];
    
    drivers.map((driver) => {
      let newObject = {
        x: (driver.posX - minX) / divider + 50,
        y: (driver.posY - minY) / divider + 50,
        place: driver.place,
      };
      newDrivers.push(newObject);
      setAnimatedDrivers(newDrivers);
    });
  }, [divider, drivers, minX, minY]);

  useEffect(() => {
    const trackNameHash = md5(trackName);
    if (trackName !== "") {
      console.log("track: ", trackName);
      fetch(`https://simracinglab.dk/trackmap/getTrack.php?map=${trackNameHash}`)
        .then((res) => res.json())
        .then((res) => {
          setMinX(res.minX);
          setMinY(res.minY);
          setMaxX(res.maxX);
          setMaxY(res.maxY);
          setCoordinates(res.map);
        });
    }
  }, [trackName]);

  useEffect(() => {
    if (div.current?.clientHeight && div.current?.clientWidth) {
      const dividerHeight = (maxY - minY) / (div.current?.clientHeight - 350);
      const dividerWidth = (maxX - minX) / (div.current?.clientWidth - 350);
      setDivider(Math.max(dividerHeight, dividerWidth));
      setCanvasHeight(Math.round((maxY - minY) / divider) + 100);
      setCanvasWidth(Math.round((maxX - minX) / divider) + 100);
    }
  }, [canvasWidth, canvasHeight, divider, maxY, minY, maxX, minX]);

  useEffect(() => {
    if (ip !== "") {
      connect();
    }
  }, [ip]);

  useEffect(() => {
    let isMounted = true;

    if (ws) {
      ws.onopen = () => {
        dispatch(setNotification({ show: true, message: "Connection established", status: NotificationStatus.SUCCESS }));
      };

      ws.onmessage = (event) => {
        if (isMounted) {
          try {
            let drivers: IDriver[] = JSON.parse(event.data);
            if (initialDriversArray.length === 0) {
              setInitialDriversArray(drivers);
            }
            dispatch(setLeaderboardDrivers(drivers));
            setDrivers(drivers);
          } catch (err) {
            console.log("Error: ", err);
          }
        }
      };

      ws.onclose = (e) => {
        console.log(e.reason);
        dispatch(setNotification({ show: true, message: "Reconnecting...", status: NotificationStatus.WARNING }));
        ws?.reconnect();
      };

      ws.onerror = (err) => {
        console.error(err);
        ws.close();
        dispatch(setNotification({ show: true, message: "Reconnecting...", status: NotificationStatus.WARNING }));
        ws?.reconnect();
      };
    }
    return () => {
      isMounted = false;
    };
  }, [ws]);

  const connect = () => {
    const portNumber = sockets.find((object) => object.name === SocketEvents.LEADERBOARD)?.port;
    if (ws === null) {
      setWs(new ReconnectingWebSocket(`ws://${ip}:${portNumber}`));
    }
  };

  const onSelectDriver = (id: number) => {
    let newSelectedDrivers: number[] = [...selectedDrivers];
    if (selectedDrivers.find((d) => d === id)) {
      newSelectedDrivers = newSelectedDrivers.filter((d) => d !== id);
      setSelectedDrivers(newSelectedDrivers);
    }
    if (!selectedDrivers.includes(id)) {
      newSelectedDrivers.push(id);
      //console.log(newSelectedDrivers);
      setSelectedDrivers(newSelectedDrivers);
    }
  };

  const getDotClass = (id: number) => {
    let classStr = "";
    let safetycarTxt = "safetycar";
    const driver: IDriver = drivers.filter((d) => d.id === id)[0];

    if (driver.driverName.toLowerCase().includes(safetycarTxt) || driver.vehicleName.toLowerCase().includes(safetycarTxt) || driver.className.toLowerCase().includes(safetycarTxt)) {
      classStr += "dot-idx1 classdot-sc";
    } else {
      console.log("Test2");
      let uniqueClassesArray = Array.from(new Set(drivers.map(obj => obj.className)));
      uniqueClassesArray = [...uniqueClassesArray].sort((a, b) =>
        a > b ? 1 : -1,
      );

      if (driver.isPlayer)
        classStr += "dot-idx-player";
      else {
        classStr += selectedDrivers.find((d) => d === id) ? "dot-selected ": "";
        classStr += "dot-idx" + driver.place;
        classStr += " classdot-" + uniqueClassesArray.indexOf(driver.className);
      }
    }
    
    return classStr;
  };

  const getDotText = (id: number) => {
    let safetycarTxt = "safetycar";
    const driver: IDriver = drivers.filter((d) => d.id === id)[0];

    if (driver.driverName.toLowerCase().includes(safetycarTxt) || driver.vehicleName.toLowerCase().includes(safetycarTxt) || driver.className.toLowerCase().includes(safetycarTxt)) {
      return "SC";
    } else {
      return driver.place;
    }
  };

  return (
    <div className="track-map" ref={div}>
      <div style={{ width: "100%", height: "100%", position: "relative"}}>
        <div className="track-map-status" style={{top: window.innerHeight / 3}}>Generating trackmap...<br/>34%</div>
        <div style={{ width: canvasWidth, height: canvasHeight, top: 0, overflow: "visible", position: "absolute", zIndex: 2 }}>
          {styles.map((animatedStyles, index) => (
            <animated.div
              key={index}
              style={{
                width: 34,
                height: 34,
                background: "#000",
                position: "absolute",
                border: "5px solid #fb923c",
                borderRadius: 99,
                display: "flex",
                justifyContent: "center",
                color: "#fff",
                alignItems: "center",
                boxShadow: "3px 3px 3px 0px rgba(0,0,0,0.75)",
                ...animatedStyles,
              }}
              className={getDotClass(index)}>
              {getDotText(index/*animatedStyles.content*/)}
            </animated.div>
          ))}
        </div>

        <Map
          coordinates={coordinates}
          canvasHeight={canvasHeight}
          canvasWidth={canvasWidth}
          smallestX={minX}
          smallestY={minY}
          biggestX={maxX}
          biggestY={maxY}
          divider={divider}
        />
      </div>

      <div className="leaderboard-mini">
        <div className="leaderboard-minified">
          {leaderboardDriver.length !== 0 ? (
            <div className="leaderboard__header-minified">
              <div className="header__center">P</div>
              <div className="header__left">Driver</div>
              <div className="header__center">Status</div>
            </div>
          ) : (
            <Skeleton variant="rectangular" animation="wave" height={40} style={{ borderRadius: 10, marginBottom: 10 }} />
          )}
          {leaderboardDriver.length !== 0 ? (
            <div>
              {Array.from(leaderboardDriver)
                .sort((a, b) => a.place - b.place)
                .map((driver) => (
                  <Driver
                    onSelect={driver.isPlayer ? () => console.log() : () => onSelectDriver(driver.id)}
                    mini={true}
                    layout
                    key={driver.id}
                    item={driver}
                    className={
                      selectedDrivers.find((d) => d === driver.id) || driver.isPlayer
                        ? "leaderboard__driver-minified leaderboard__driver--selected"
                        : "leaderboard__driver-minified"
                    }
                  />
                ))}
            </div>
          ) : (
            <>
              {Array.apply(null, Array(20)).map((value, index) => (
                <Skeleton key={index} variant="rectangular" animation="wave" height={50} style={{ borderRadius: 10, marginBottom: 10 }} />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrackMap;
