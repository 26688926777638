import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB3HLZc8Z-unEezkTEMswbwnz1oyS7eOO8",
  authDomain: "pit-tool.firebaseapp.com",
  databaseURL:
    "https://pit-tool-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "pit-tool",
  storageBucket: "pit-tool.appspot.com",
  messagingSenderId: "300924042642",
  appId: "1:300924042642:web:04ea11c8eac06351bad54b",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
export const auth = getAuth(app);
export default app;
