import Driver from "components/Leaderboard/Driver";
import { useEffect, useState } from "react";
import "styles/components/leaderboard.css";
import { useAppSelector } from "store/hooks";
import { Skeleton } from "@mui/material";
import { IDriver } from "models/IDriver";
import { SocketEvents } from "models/Sockets";
import { setNotification } from "store/reducers/notificationSlice";
import NotificationStatus from "models/NotificationStatus";
import { useDispatch } from "react-redux";
import ReconnectingWebSocket from "reconnecting-websocket";

function Leaderboard() {
  const { ip, sockets } = useAppSelector((state) => state.socket);
  const [ws, setWs] = useState<ReconnectingWebSocket | null>(null);
  const [drivers, setDrivers] = useState<IDriver[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (ip !== "") {
      connect();
    }
  }, [ip]);

  useEffect(() => {
    let isMounted = true; // note mutable flag

    if (ws) {
      ws.onopen = () => {
        dispatch(setNotification({ show: true, message: "Connection established", status: NotificationStatus.SUCCESS }));
      };

      ws.onmessage = (event) => {
        if (isMounted) {
          try {
            let drivers: IDriver[] = JSON.parse(event.data);
            drivers = drivers.sort((a: IDriver, b: IDriver) => {
              return a.place - b.place;
            });
            setDrivers(drivers);
          } catch (err) {
            console.log("Error: ", err);
          }
        }
      };

      ws.onclose = (e) => {
        console.log(e.reason);
        dispatch(setNotification({ show: true, message: "Reconnecting...", status: NotificationStatus.WARNING }));
        ws?.reconnect();
      };

      ws.onerror = (err) => {
        console.error(err);
        ws.close();
        dispatch(setNotification({ show: true, message: "Reconnecting...", status: NotificationStatus.WARNING }));
        ws?.reconnect();
      };
    }
    return () => {
      isMounted = false;
    };
  }, [ws]);

  const connect = () => {
    const portNumber = sockets.find((object) => object.name === SocketEvents.LEADERBOARD)?.port;
    if (ws === null) {
      setWs(new ReconnectingWebSocket(`ws://${ip}:${portNumber}`));
    }
  };

  const getCarClass = (id: string) => {
    let uniqueClassesArray = Array.from(new Set(drivers.map(obj => obj.className)));
    uniqueClassesArray = [...uniqueClassesArray].sort((a, b) =>
      a > b ? 1 : -1,
    );
    return "carclass-" + uniqueClassesArray.indexOf(id);
  };

  return (
    <div className="leaderboard__container">
      <div className="leaderboard">
        {drivers.length !== 0 ? (
          <div className="leaderboard__header">
            <div className="header__center">P</div>
            <div className="header__left">Driver</div>
            <div className="header__center">Vehicle</div>
            <div className="header__center">Class</div>
            <div className="header__center">Laps</div>
            <div className="header__center">Gap</div>
            <div className="header__center">Sector 1</div>
            <div className="header__center">Sector 2</div>
            <div className="header__center">Sector 3</div>
            <div className="header__center">Last lap</div>
            <div className="header__center">Best lap</div>
            <div className="header__center">Status</div>
          </div>
        ) : (
          <Skeleton variant="rectangular" animation="wave" height={40} style={{ borderRadius: 10, marginBottom: 10 }} />
        )}
        {drivers.length !== 0 ? (
          <div>
            {drivers.map((driver) => (
              <Driver
                layout
                mini={false}
                key={driver.id}
                item={driver}
                className={"leaderboard__driver " + getCarClass(driver.className)}
              />
            ))}
          </div>
        ) : (
          <>
            {Array.apply(null, Array(20)).map((value, index) => (
              <Skeleton key={index} variant="rectangular" animation="wave" height={50} style={{ borderRadius: 10, marginBottom: 10 }} />
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default Leaderboard;
