import { memo, useEffect, useState } from "react";
import { Stage, Layer, Shape, Circle, Text } from "react-konva";
//import { Spring, animated } from "@react-spring/konva";

const Map = ({ coordinates, canvasHeight, canvasWidth, divider, smallestX, smallestY, biggestX, biggestY }) => {
  return (
    <Stage width={canvasWidth} height={canvasHeight}>
      <Layer listening={false}>
        <Shape
          sceneFunc={(context, shape) => {
            context.beginPath();

            coordinates.map((cor) => {
              if (canvasHeight) {
                context.lineTo((cor.x - smallestX) / divider + 50 + 17, (cor.y - smallestY) / divider + 50 + 17);
              }
            });

            context.closePath();
            // (!) Konva specific method, it is very important
            context.fillStrokeShape(shape);
          }}
          stroke="#ccc"
          strokeWidth={10}
          lineJoin="bevel"
          lineCap="round"
        />
      </Layer>
    </Stage>
  );
};

export default memo(Map);
