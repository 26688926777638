import { TyreBrakeLeftIcon, TyreBrakeRightIcon } from "helpers/icons";
import { IWheelData } from "models/IWheelData";

interface Props {
  reverse?: boolean;
  data: IWheelData;
}
const Tyre = ({ reverse, data }: Props) => {
  return (
    <div className="tyres">
      <div className="psi">
        {reverse ? (
          <div className="tyres-temp-container">
            <div className="tyres-temp-value">{`${data.brakeTemp}°`}</div>
            <img alt="Tyre Brake Icon" height={100} src={reverse ? TyreBrakeLeftIcon : TyreBrakeRightIcon} className="tyres-brake-icon" />
          </div>
        ) : null}

        <div className="tyres-psi">
          <div className="tyres-temp">{`${data.carCassTemp}°`}</div>
          <div className="tyres-psi-container">
            <div
              className="tyres-psi-1 transition-color"
              style={{
                backgroundColor:
                  data.leftTemp > 0 && data.leftTemp <= 50 ? "#3c72fb" : data.leftTemp <= 75 && data.leftTemp > 50 ? "#469626" : "#fb3c3c",
              }}
            >{`${data.leftTemp}°`}</div>
            <div
              className="tyres-psi-2 transition-color"
              style={{
                backgroundColor: data.centerTemp <= 50 ? "#3c72fb" : data.centerTemp <= 75 && data.centerTemp > 50 ? "#469626" : "#fb3c3c",
              }}
            >{`${data.centerTemp}°`}</div>
            <div
              className="tyres-psi-3 transition-color"
              style={{
                backgroundColor: data.rightTemp <= 50 ? "#3c72fb" : data.rightTemp <= 75 && data.rightTemp > 50 ? "#469626" : "#fb3c3c",
              }}
            >{`${data.leftTemp}°`}</div>
          </div>
          <div className="psi-value">{`${data.pressure} PSI`}</div>
        </div>
        {reverse ? null : (
          <div className="tyres-temp-container">
            <div className="tyres-temp-value">{`${data.brakeTemp}°`}</div>
            <img alt="Tyre Brake Icon" height={100} src={reverse ? TyreBrakeLeftIcon : TyreBrakeRightIcon} className="tyres-brake-icon" />
          </div>
        )}
      </div>

      <div className="tyres-percentage">
        <div className="progress-bar" style={{ justifyContent: reverse ? "flex-start" : "flex-end" }}>
          <div className="progress-bar-value" style={{ width: `${data.wear}%` }}></div>
        </div>

        <div className="percentage-value">{`${data.wear}%`}</div>
      </div>
    </div>
  );
};

export default Tyre;
