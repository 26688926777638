import { Action, ThunkAction, configureStore, Tuple } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import {thunk} from "redux-thunk";
import crashMiddleware from "./middleware/crashMiddleware";
import loggerMiddleware from "./middleware/loggerMiddleware";
import reducer from "./reducer";

export const store = configureStore({
  reducer: reducer,
  middleware: () => new Tuple(thunk, crashMiddleware, loggerMiddleware)
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
