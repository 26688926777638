const CustomTooltip = ({ active, payload, label }: any) => {
  return (
    <>
      {active && payload && payload.length ? (
        <div className="custom-tooltip">
          <p className="label">{`Second - ${label / 10}`}</p>
          <p className="label">{`Throttle: ${payload[0].value}`}</p>
          <p className="label">{`Brake: ${payload[1].value}`}</p>
        </div>
      ) : null}
    </>
  );
};

export default CustomTooltip;
