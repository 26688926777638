import { createSlice } from "@reduxjs/toolkit";
import NotificationStatus from "models/NotificationStatus";

type AlertColor = "success" | "info" | "warning" | "error";

export type NotificationState = {
  show: boolean;
  message: string;
  status: AlertColor;
};

const initialState: NotificationState = {
  show: false,
  message: "",
  status: NotificationStatus.SUCCESS,
};

export const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState,
  reducers: {
    setShowNotification(state, action) {
      state.show = action.payload;
    },
    setNotification(state, action) {
      state.show = action.payload.show;
      state.message = action.payload.message;
      state.status = action.payload.status;
    },
  },
  //extraReducers: {},
});

export const { setShowNotification, setNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
