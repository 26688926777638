import { motion } from "framer-motion";

interface Props {
  children: JSX.Element;
  className?: string;
}

const TransitionItem = ({ children, className }: Props) => {
  return (
    <motion.div
      transition={{
        type: "spring",
        damping: 50,
        stiffness: 200,
      }}
      variants={{ initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 } }}
      initial="initial"
      animate="animate"
      exit="exit"
      className={className ? className : "transition_item"}
    >
      {children}
    </motion.div>
  );
};

export default TransitionItem;
