import { createSlice } from "@reduxjs/toolkit";
import { IDriver } from "models/IDriver";
import { ISocketItem } from "models/ISocketItem";
export interface SocketState {
  ip: string;
  port: number;
  sockets: ISocketItem[];
  leaderboardDriver: IDriver[];
  trackName: string;
}
//80.208.104.167:9002

const initialState: SocketState = {
  ip: "",
  port: 5001,
  sockets: [],
  leaderboardDriver: [],
  trackName: "",
};

const socketSlice = createSlice({
  name: "socket",
  initialState,
  reducers: {
    setIp: (state, action) => {
      state.ip = action.payload;
    },
    setPort: (state, action) => {
      state.port = action.payload;
    },
    setSockets: (state, action) => {
      state.sockets = action.payload;
    },
    setLeaderboardDrivers: (state, action) => {
      let drivers = [...action.payload];
      state.leaderboardDriver = drivers.sort((a, b) => a.place - b.place);
    },
    setTrackName: (state, action) => {
      state.trackName = action.payload;
    },
  },
});

export const { setIp, setPort, setSockets, setLeaderboardDrivers, setTrackName } = socketSlice.actions;

export default socketSlice.reducer;
