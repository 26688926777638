import { useNavigate } from "react-router-dom";
import "styles/components/notfound.css";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="notfound">
      <div className="text-404">404</div>
      <div className="text-notfound">We think you are lost ...</div>
      <button onClick={() => navigate("/leaderboard")} className="button-404">
        Back to home
      </button>
    </div>
  );
};

export default NotFound;
