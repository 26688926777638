import Layout from "components/Layout/Layout";
import { AnimatePresence } from "framer-motion";
import Dashboard from "pages/Dashboard";
import Leaderboard from "pages/Leaderboard";
import NotFound from "pages/NotFound";
import Settings from "pages/Settings";
import SignIn from "pages/SignIn";
import TrackMap from "pages/TrackMap";
import { Provider } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "store/store";

function App() {
  return (
    <AnimatePresence exitBeforeEnter={true}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Routes>
            <Route path="/" element={<SignIn />} />
            <Route
              path="/settings"
              element={
                <Layout>
                  <Settings />
                </Layout>
              }
            />
            <Route path="*" element={<NotFound />} />

            <Route
              path="/leaderboard"
              element={
                <Layout>
                  <Leaderboard />
                </Layout>
              }
            />
            <Route
              path="/dashboard"
              element={
                <Layout>
                  <Dashboard />
                </Layout>
              }
            />
            <Route
              path="/track-map"
              element={
                <Layout>
                  <TrackMap />
                </Layout>
              }
            />
          </Routes>
        </PersistGate>
      </Provider>
    </AnimatePresence>
  );
}

export default App;
