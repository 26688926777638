import { useNavigate } from "react-router-dom";
import "styles/components/signin.css";
import simracing_logo from "assets/simracing-logo.png";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { setDev } from "store/reducers/authSlice";
import GoogleLogin from "../Authentication/GoogleLogin";
import FacebookLogin from "../Authentication/FacebookLogin";
import { useEffect } from "react";
import { useAppSelector } from "store/hooks";


const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useAppSelector((state) => state.auth)

  const responseDevelopment = () => {
    dispatch(setDev(true));
    navigate("/leaderboard");
  };

  useEffect(() => {
    if (user == null || !user) return;
    if (user.accessToken) {
      navigate("/leaderboard");
    }
  }, [user, navigate]);


  return (
    <div className="signin">
      <motion.div
        className="signin__container"
        initial={{ scale: 0.75 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div>
          <h1>Get Started</h1>
        </div>
        <motion.div
          className="button-container"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.25, duration: 0.5 }}
        >
          <button className="social-button " onClick={responseDevelopment}>
            <img src={simracing_logo} alt="SimRacingLab" />
            <div>Beta Test</div>
          </button>
        </motion.div>
        <motion.div
          className="button-container"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.25, duration: 0.5 }}
        >
          <GoogleLogin />
        </motion.div>
        <motion.div
          className="button-container"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.25, duration: 0.5 }}
        >
          <FacebookLogin />
        </motion.div>
        {/*
        <motion.div
          className="button-container"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.25, duration: 0.5 }}
        >
          <FacebookLogin
            appId="662411731524522"
            fields="name,email"
            callback={responseFacebook}
            render={(renderProps) => (
              <button disabled={false} className="social-button" onClick={renderProps.onClick}>
                <img src={fb_logo} alt="facebook" />
                <div>Continue with Facebook</div>
              </button>
            )}
          />
        </motion.div>
        */}
      </motion.div>
    </div>
  );
};

export default SignIn;
