import { FacebookAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../firebase/firebaseConfig";
import facebook_logo from "assets/fb-logo.png";
import { getDatabase, ref, set } from "firebase/database";
import { useAppDispatch } from "store/hooks";
import { setUser } from "store/reducers/authSlice";

function FacebookLogin() {
  const dispatch = useAppDispatch()
  const handleClick = () => {
    const provider = new FacebookAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        if (result !== null) {
          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          const credential = FacebookAuthProvider.credentialFromResult(result)!;
          const db = getDatabase();
          set(ref(db, "users/" + result.user.uid), {
            uid: result.user.uid,
            email: result.user.email,
            name: result.user.displayName,
            provider: result.user.providerData[0].providerId,
            photoUrl: result.user.photoURL,
          });

          //Update the auth slice
          dispatch(setUser({
            uid: result.user.uid,
            name: result.user.displayName,
            email: result.user.email,
            photoUrl: result.user.photoURL,
            accessToken: credential.accessToken
          }))
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <button className="social-button" onClick={handleClick}>
        <img src={facebook_logo} alt="Facebook" />
        <div>Sign in with Facebook</div>
      </button>
    </div>
  );
}

export default FacebookLogin;
