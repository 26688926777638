interface Props {
  Icon?: string;
  text?: string;
  value: string;
}
const CarInfo = ({ Icon, value, text }: Props) => {
  return (
    <div className="car__info">
      {Icon && <img alt={value} height={30} src={Icon} />}
      {text && <div className="car__info-text">{text}</div>}
      <div className="car__info-value">{value}</div>
    </div>
  );
};

export default CarInfo;
