import User from "models/User";
import { RootState } from "../store";
import { createSlice } from "@reduxjs/toolkit";



export type AuthState = {
  user: null | undefined | User;
  dev: boolean;
  headerShown: boolean;
  feedbackShown: boolean;
  temperatureType: "celsius" | "fahrenheit";
};

const initialState: AuthState = {
  user: undefined,
  dev: false,
  headerShown: true,
  feedbackShown: false,
  temperatureType: "celsius",
};

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    setHeaderShown(state, action) {
      state.headerShown = action.payload;
    },
    setFeedbackShown(state, action) {
      state.feedbackShown = action.payload;
    },

    setTemperatureType(state, action) {
      state.temperatureType = action.payload;
    },
    setDev(state, action) {
      state.dev = action.payload;
    },
  },

  //extraReducers: {},
});

export const selectAuth = (state: RootState) => state.auth;

export const { setUser, setDev, setHeaderShown, setFeedbackShown, setTemperatureType } = authSlice.actions;
export default authSlice.reducer;
