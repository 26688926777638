import NotificationStatus from "models/NotificationStatus";
import { useEffect, useState } from "react";
import { useAppDispatch } from "store/hooks";
import { setNotification } from "store/reducers/notificationSlice";
import { setIp, setSockets } from "store/reducers/socketSlice";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme } from "@mui/system";
import { ThemeProvider } from "@emotion/react";
import { isIpValid } from "helpers/isIpValid";
import "styles/components/welcome.css";
import { motion } from "framer-motion";
import { initSockets } from "models/Sockets";
import { useSearchParams } from "react-router-dom";

const Welcome = () => {
  const [ipAddress, setIpAddress] = useState("");
  //const { port } = useAppSelector((state) => state.socket);
  const [loading, setLoading] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const theme = createTheme({
    palette: {
      primary: {
        main: "#fb923c",
      },
    },
  });

  useEffect(() => {
    let ip = searchParams.get("ip")
    if (ip == null) return;
    setIsReload(true);
    prepareSocket(ip);
  }, [])

  const handleContinue = (e: any) => {
    e.preventDefault();
    prepareSocket(ipAddress);
  };

  return (
    <>
      {!isReload && <motion.div className="backdrop" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <motion.div
          className="welcome__container"
          onClick={(e) => e.stopPropagation()}
          variants={{
            hidden: {
              y: "-100vh",
              opacity: 0,
            },
            visible: {
              y: "0",
              opacity: 1,
              transition: {
                duration: 0.1,
                type: "spring",
                damping: 25,
                stiffness: 500,
              },
            },
            exit: {
              y: "100vh",
              opacity: 0,
            },
          }}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <form className="welcome__container-inner" onSubmit={(e) => handleContinue(e)}>
            <div className="ip__address-container">
              <div className="ip__label">IP Address</div>
              {loading ? (
                <div className="loading__container-inner">
                  <ThemeProvider theme={theme}>
                    <CircularProgress
                      sx={{
                        marginRight: 1,
                      }}
                      size={20}
                      color="primary"
                    />
                  </ThemeProvider>
                  verifying connection ...
                </div>
              ) : null}

              <input
                className="id-input"
                type="text"
                onChange={(e) => setIpAddress(e.target.value)}
                minLength={7}
                maxLength={21}
                placeholder="1.2.3.4"
              />
            </div>
            <div className="button__container">
              <button className="ip__button" onClick={(e) => handleContinue(e)}>
                GO!
              </button>
            </div>
          </form>
        </motion.div>
      </motion.div>}
    </>
  );

  function prepareSocket(ipAddress: string) {
    setLoading(true);
    if (isIpValid(ipAddress)) {
      let port = "9002";
      if (ipAddress.includes(":")) {
        //dispatch(setPort(ipAddress.split(":")[1]));
        port = ipAddress.split(":")[1];
      }
      let connectionUrl = `ws://${ipAddress.includes(":") ? ipAddress : ipAddress + ":" + port}`;
      let socket = new WebSocket(connectionUrl);
      socket.onopen = () => {
        dispatch(setNotification({ show: true, message: "Connection established", status: NotificationStatus.SUCCESS }));
        setIpAddress("");

        const initialSockets = initSockets(ipAddress.includes(":") ? parseInt(ipAddress.split(":")[1]) : parseInt(port));
        dispatch(setSockets(initialSockets));

        let ip = ipAddress.includes(":") ? ipAddress.split(":")[0] : ipAddress;
        dispatch(setIp(ip));

        //Set as query parameter to allow refreshes.
        setSearchParams({ ip: ip })
        setLoading(false);
        socket.close();
      };

      socket.onerror = () => {
        dispatch(setNotification({ show: true, message: "Connection error...", status: NotificationStatus.ERROR }));
        setLoading(false);
        socket.close();
      };

      socket.onclose = () => {
        setLoading(false);
        socket.close();
      };
    } else {
      dispatch(setNotification({ show: true, message: "Invalid IP Address. Try again.", status: NotificationStatus.WARNING }));
      setLoading(false);
    }
  }


};

export default Welcome;
