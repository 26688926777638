import { motion } from "framer-motion";
import { HelmetIcon } from "helpers/icons";
import { IDriver } from "models/IDriver";
import { memo, MouseEventHandler } from "react";
import "styles/components/driver.css";
interface DriverProps {
  item: IDriver;
  layout: any;
  className: string;
  mini?: boolean;
  onSelect?: MouseEventHandler<HTMLDivElement> | undefined;
}
function Driver({ item, layout, className, mini, onSelect }: DriverProps) {
  return (
    <motion.div
      key={item.id}
      onClick={onSelect}
      layout={layout}
      transition={{
        type: "spring",
        damping: 50,
        stiffness: 200,
      }}
      variants={{ initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 } }}
      initial="initial"
      animate="animate"
      exit="exit"
      className={className}
    >
      <div className="header__center header__place">
        {item.isPlayer ? (
          <span className="header__place-current">
            <img alt="driver" height={20} src={HelmetIcon} />
          </span>
        ) : null}
        <span>{item.place}</span>
      </div>
      <div className="header__left header__orange">
        <p>{item.driverName}</p>
      </div>
      {mini ? null : (
        <div className="header__center header__orange">
          <p>{item.vehicleName}</p>
        </div>
      )}
      {mini ? null : (
        <div className="header__center header__orange header__class">
          <p><span className="badge">{item.className}</span></p>
        </div>
      )}
      {mini ? null : (
        <div className="header__center">
          <span>{item.laps}</span>
        </div>
      )}
      {mini ? null : (
        <div className="header__center">
          <span>{item.gap}</span>
        </div>
      )}
      {mini ? null : (
        <div className="header__center">
          <span>{item.split1}</span>
        </div>
      )}
      {mini ? null : (
        <div className="header__center">
          <span>{item.split2}</span>
        </div>
      )}
      {mini ? null : (
        <div className="header__center">
          <span>{item.split3}</span>
        </div>
      )}
      {mini ? null : (
        <div className="header__center">
          <span>{item.lastLap}</span>
        </div>
      )}
      {mini ? null : (
        <div className="header__center">
          <span>{item.bestLap}</span>
        </div>
      )}
      <div className="header__center">
        <span>{item.track}</span>
      </div>
    </motion.div>
  );
}

export default memo(Driver);
