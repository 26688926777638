import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../firebase/firebaseConfig";
import google_logo from "assets/google-logo.png";
import { getDatabase, ref, set } from "firebase/database";
import { setUser } from "store/reducers/authSlice";
import { useAppDispatch } from "store/hooks";

function GoogleLogin() {
  const dispatch = useAppDispatch()

  const handleClick = () => {
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        if (result !== null) {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result)!;
          const db = getDatabase();
          set(ref(db, "users/" + result.user.uid), {
            uid: result.user.uid,
            email: result.user.email,
            name: result.user.displayName,
            provider: result.user.providerData[0].providerId,
            photoUrl: result.user.photoURL,
          });
          //Update auth state 
          dispatch(setUser({
            uid: result.user.uid,
            name: result.user.displayName,
            email: result.user.email,
            photoUrl: result.user.photoURL,
            accessToken: credential.accessToken
          }))
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <button className="social-button" onClick={handleClick}>
        <img src={google_logo} alt="Google" />
        <div>Sign in with Google</div>
      </button>
    </div>
  );
}

export default GoogleLogin;
