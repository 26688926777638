import { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";

interface Props {
  name: string;
  up: boolean;
  value: number;
  color?: string;
}

const ProgressBarContainer = ({ name, up, value, color }: Props) => {
  const [currentValue, setValue] = useState(0);
  const [prevValue, setPrevValue] = useState(0);

  const props = useSpring({ val: currentValue, from: { val: prevValue } });

  useEffect(() => {
    setPrevValue(currentValue);
    setValue(value);
  }, [value]);

  return (
    <div className="progress-bar-container">
      {up ? <div className="bar-title">{name}</div> : null}
      <div className="progress-bar-inner">
        <div style={{ display: "flex" }}>
          <animated.div className="progress-bar-inner-value">{props.val.to((n: number) => Math.floor(n))}</animated.div>
          <div className="progress-bar-inner-value">%</div>
        </div>
        <div className="progress-bar progress-bar-chart">
          <div className="progress-bar-value" style={{ height: `${value}%`, background: color }}></div>
        </div>
      </div>
      {up ? null : <div className="bar-title">{name}</div>}
    </div>
  );
};

export default ProgressBarContainer;
