import car from "assets/car.png";

interface Props {
  carDamage: number[];
}
const CarDamage = ({ carDamage }: Props) => {
  return (
    <div className="car">
      <div className="car-image">
        <div className="car-skeleton">
          <div
            className="car-front"
            style={{
              background: carDamage[0] === 1 ? " #fb723c" : carDamage[0] === 2 ? " #fb3c3c" : "",
            }}
          ></div>
          <div className="car-wheels">
            <div
              className="car-inner-1"
              style={{
                background: carDamage[1] === 1 ? " #fb723c" : carDamage[1] === 2 ? " #fb3c3c" : "",
              }}
            ></div>
            <div className="car-inner-2"></div>
            <div
              className="car-inner-1"
              style={{
                background: carDamage[7] === 1 ? " #fb723c" : carDamage[7] === 2 ? " #fb3c3c" : "",
              }}
            ></div>
          </div>
          <div className="car-side">
            <div
              className="car-inner-1"
              style={{
                background: carDamage[2] === 1 ? " #fb723c" : carDamage[2] === 2 ? " #fb3c3c" : "",
              }}
            ></div>
            <div className="car-inner-2"></div>
            <div
              className="car-inner-1"
              style={{
                background: carDamage[6] === 1 ? " #fb723c" : carDamage[6] === 2 ? " #fb3c3c" : "",
              }}
            ></div>
          </div>
          <div className="car-wheels">
            <div
              className="car-inner-1"
              style={{
                background: carDamage[3] === 1 ? " #fb723c" : carDamage[3] === 2 ? " #fb3c3c" : "",
              }}
            ></div>
            <div className="car-inner-2"></div>
            <div
              className="car-inner-1"
              style={{
                background: carDamage[5] === 1 ? " #fb723c" : carDamage[5] === 2 ? " #fb3c3c" : "",
              }}
            ></div>
          </div>
          <div
            className="car-back"
            style={{
              background: carDamage[4] === 1 ? " #fb723c" : carDamage[4] === 2 ? " #fb3c3c" : "",
            }}
          ></div>
        </div>

        <img src={car} height={400} alt="car" />
      </div>
    </div>
  );
};

export default CarDamage;
