import { combineReducers } from "@reduxjs/toolkit";
import persistReducer from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import authSlice from "./reducers/authSlice";
import notificationSlice from "./reducers/notificationSlice";
import socketSlice from "./reducers/socketSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};
const reducers = combineReducers({
  auth: authSlice,
  socket: socketSlice,
  notifications: notificationSlice,
});

export default persistReducer(persistConfig, reducers);
