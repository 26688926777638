import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { BellIcon, EyeIcon, EyeOffIcon, LogoutIcon } from "@heroicons/react/outline";
import { MdOutlineFeedback } from "react-icons/md";
import logo from "assets/logo.png";
import "styles/components/navigation.css";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setDev, setFeedbackShown, setHeaderShown, setUser } from "store/reducers/authSlice";
import { Tooltip } from "@mui/material";
import Feedback from "./Feedback";
import { setIp, setLeaderboardDrivers, setSockets, setTrackName } from "store/reducers/socketSlice";

function Navigation() {
  const { headerShown, feedbackShown } = useAppSelector((state) => state.auth);
  const { ip } = useAppSelector((state) => state.socket)
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const logoutClick = () => {
    dispatch(setUser({}));
    dispatch(setDev(false));
    dispatch(setIp(""));
    dispatch(setSockets([]))
    dispatch(setLeaderboardDrivers([]));
    dispatch(setTrackName(""));
    navigate("/")
  }

  return (
    <nav className="navigation">
      <div className="navigation-left">
        <img src={logo} alt="logo" className="logo" />
        <div>{process.env.REACT_APP_VERSION}</div>
      </div>
      <div className="navigation-center">
        <NavLink to={`/leaderboard?${searchParams}`} className={(navData) => `${(navData.isActive ? "navlink-active" : "navlink")} ${(ip === "" ? "navlink-disabled" : "")}`}>
          Leaderboard
        </NavLink>
        <NavLink to={`/dashboard?${searchParams}`} className={(navData) => `${(navData.isActive ? "navlink-active" : "navlink")} ${(ip === "" ? "navlink-disabled" : "")}`}>
          Dashboard
        </NavLink>
        <NavLink to={`/track-map?${searchParams}`} className={(navData) => `${(navData.isActive ? "navlink-active" : "navlink")} ${(ip === "" ? "navlink-disabled" : "")}`}>
          Track Map
        </NavLink>
        {/* <NavLink to="/strategy" className={(navData) => (navData.isActive ? "navlink-active" : "navlink")}>
          Strategy
        </NavLink> */}
      </div>
      <div className="navigation-right">
        <Tooltip title={headerShown ? "Hide Header" : "Show Header"} className={`${ip === "" ? "navlink-disabled" : ""}`}>
          <div className="icon-container" onClick={() => dispatch(setHeaderShown(!headerShown))}>
            {headerShown ? <EyeIcon className="icon" /> : <EyeOffIcon className="icon" />}
          </div>
        </Tooltip>
        <Tooltip title="Feedback" className={`${ip === "" ? "navlink-disabled" : ""}`}>
          <div className="icon-container" onClick={() => dispatch(setFeedbackShown(!feedbackShown))}>
            <MdOutlineFeedback className="icon" />
          </div>
        </Tooltip>
        {feedbackShown ? <Feedback /> : null}

        <Tooltip title="Notifications" className={`${ip === "" ? "navlink-disabled" : ""}`}>
          <div className="icon-container">
            <BellIcon className="icon" />
          </div>
        </Tooltip>

        <Tooltip title="Logout">
          <div className="icon-container" onClick={logoutClick}>
            <LogoutIcon className="icon" />
          </div>
        </Tooltip>

        {/*
        <NavLink to="/settings" className={(navData) => (navData.isActive ? "account-avatar account-avatar-active" : "account-avatar")}>
          <div className="avatar-icon">VR</div>
        </NavLink>
        */}
      </div >
    </nav >
  );
}

export default Navigation;
