import { Skeleton } from "@mui/material";
import CarInfo from "components/Dashboard/CarInfo";
import Speedometer from "components/Dashboard/Speedometer";
import Tyre from "components/Dashboard/Tyre";
import { ABSIcon, BrakeIcon, FuelIcon, OilIcon, OilUseIcon, StartFinishIcon, TCSIcon, TemperatureIcon } from "helpers/icons";
import { IDashboardData } from "models/IDashboardData";
import { SocketEvents } from "models/Sockets";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import "styles/components/dashboard.css";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import ProgressBarContainer from "components/Dashboard/ProgressBarContainer";
import { setNotification } from "store/reducers/notificationSlice";
import NotificationStatus from "models/NotificationStatus";
import DashboardItem from "components/Layout/TransitionItem";
import ReconnectingWebSocket from "reconnecting-websocket";
import CarDamage from "components/Dashboard/CarDamage";
import Tyres from "components/Dashboard/Tyres";
import DashboardChart from "components/Dashboard/DashboardChart";

const Dashboard = () => {
  const { ip, sockets } = useAppSelector((state) => state.socket);
  const [ws, setWs] = useState<ReconnectingWebSocket | null>(null);
  const [dashboardData, setDashboardData] = useState<IDashboardData | undefined>(undefined);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (ip !== "") {
      connect();
    }
  }, [ip]);

  useEffect(() => {
    let isMounted = true; // note mutable flag

    if (ws) {
      ws.onopen = () => {
        dispatch(setNotification({ show: true, message: "Connection established", status: NotificationStatus.SUCCESS }));
      };

      ws.onmessage = (event) => {
        if (isMounted) {
          try {
            let data = JSON.parse(event.data);
            setDashboardData(data);
          } catch (err) {
            console.log("Error: ", err);
          }
        }
      };

      ws.onclose = (e) => {
        console.log(e.reason);
        dispatch(setNotification({ show: true, message: "Reconnecting...", status: NotificationStatus.WARNING }));
        ws?.reconnect();
      };

      ws.onerror = (err) => {
        console.error(err);
        ws.close();
        dispatch(setNotification({ show: true, message: "Reconnecting...", status: NotificationStatus.WARNING }));
        ws?.reconnect();
      };
    }
    return () => {
      isMounted = false;
    };
  }, [ws]);

  const connect = () => {
    const portNumber = sockets.find((object) => object.name === SocketEvents.DASHBOARD)?.port;
    if (ws === null) {
      setWs(new ReconnectingWebSocket(`ws://${ip}:${portNumber}`));
    }
  };

  return (
    <div className="dashboard-container">
      <div className="top-container">
        {dashboardData?.fuelLeft ? (
          <DashboardItem>
            <div>
              <CarInfo Icon={FuelIcon} value={`${dashboardData?.fuelLeft.toFixed(2)} L`} />
              <CarInfo Icon={OilUseIcon} value={`${dashboardData?.fuelPerLap}/Lap`} />
              <CarInfo Icon={TemperatureIcon} value={`${dashboardData?.engineWaterTemp}°`} />
              <CarInfo Icon={OilIcon} value={`${dashboardData?.engineOilTemp}°`} />
              <CarInfo Icon={StartFinishIcon} value={`${dashboardData?.fuelLapsLeft} Laps`} />
              <CarInfo Icon={BrakeIcon} value={`${dashboardData?.brake}`} />
              <CarInfo Icon={TCSIcon} value={`${dashboardData?.tcs}`} />
              <CarInfo Icon={ABSIcon} value={`${dashboardData?.abs ? "ON" : "OFF"}`} />
            </div>
          </DashboardItem>
        ) : (
          <Skeleton variant="rectangular" height="auto" animation="wave" style={{ borderRadius: 10 }} />
        )}
        <div className="speedometers__info">
          {dashboardData?.speed !== undefined ? (
            <DashboardItem>
              <div className="speedometers">
                <div>
                  <Speedometer speed={dashboardData?.speed} rpm={false} />
                  <CarInfo text="Max Speed" value={`${dashboardData?.maxSpeed}`} />
                  <CarInfo text="Max Speed Last Lap" value={`${dashboardData?.maxSpeedLastLap}`} />
                </div>
                <div className="gear-container">
                  <div className="gear-text">Gear</div>
                  <div className="gear">{dashboardData?.gear}</div>
                </div>
                <div>
                  <Speedometer speed={dashboardData?.engineRPM} rpm={true} />
                  <CarInfo text="Max RPM" value={`${dashboardData?.engineMaxRPM}`} />
                  <CarInfo text="Max RPM Last Lap" value={`${dashboardData?.engineMaxRPMLastLap}`} />
                </div>
              </div>
            </DashboardItem>
          ) : (
            <Skeleton variant="rectangular" height={516} animation="wave" style={{ borderRadius: 10 }} />
          )}
          <div className="speedometers-bottom">
            {dashboardData?.speed !== undefined ? (
              <DashboardItem>
                <div>
                  <CarInfo text="Current Lap" value={`${dashboardData?.currentLap ? dashboardData?.currentLap : "-"}`} />
                  <CarInfo text="Fastest Lap" value={`${dashboardData?.fastestLap ? dashboardData?.fastestLap : "-"}`} />
                  <CarInfo text="Last Lap" value={`${dashboardData?.lastLap ? dashboardData?.lastLap : "-"}`} />
                </div>
              </DashboardItem>
            ) : (
              <Skeleton variant="rectangular" height={198} animation="wave" style={{ borderRadius: 10 }} />
            )}
            {dashboardData?.pitReq !== undefined ? (
              <DashboardItem>
                <div className="indicators">
                  <div className={`indicator ${dashboardData?.ignition === 0 ? "" : "indicator-active"}`}>Ignition</div>
                  <div className={`indicator ${dashboardData?.pitReq ? "indicator-active" : ""}`}>Pit Reg</div>
                  <div className={`indicator ${dashboardData?.lights === 0 ? "" : "indicator-active"}`}>Lights</div>
                  <div className={`indicator ${dashboardData?.pitLim === 0 ? "" : "indicator-active"}`}>Pit Lim</div>
                  <div className={`indicator ${dashboardData?.drs === 0 ? "" : "indicator-active"}`}>DRS</div>
                  <div className={`indicator ${dashboardData?.ers === 0 ? "" : "indicator-active"}`}>ERS</div>
                </div>
              </DashboardItem>
            ) : (
              <Skeleton variant="rectangular" height={198} animation="wave" style={{ borderRadius: 10 }} />
            )}
          </div>
        </div>
        <Tyres wheels={dashboardData?.wheels} tyreNameFront={dashboardData?.tyreNameFront} tyreNameRear={dashboardData?.tyreNameRear} />
      </div>
      <div className="bottom-container">
        {dashboardData ? (
          <DashboardItem>
            <div className="damage-container">
              <div className="car_damage">
                <div className={`${dashboardData?.damaged ? "glow" : " "} car-damage-info damage`}>Car Damage</div>
                <div className={` ${dashboardData?.engineDamaged ? "glow" : ""} car-damage-info damage`}>Engine Damage</div>
                <div className={` ${dashboardData?.detached ? "glow" : ""} car-damage-info damage`}>
                  <div>Part detached</div>
                  {/* <div className="car-damage-info-value">4 laps</div> */}
                </div>
                <div className="car-damage-info ">
                  <div>Penalties</div>
                  <div className="car-damage-info-value">{dashboardData?.penalties}</div>
                </div>
              </div>
              <CarDamage carDamage={dashboardData.carDamage} />
            </div>
          </DashboardItem>
        ) : (
          <Skeleton variant="rectangular" height={426} animation="wave" style={{ borderRadius: 10 }} />
        )}
        {dashboardData ? (
          <DashboardItem>
            <DashboardChart throttlePos={dashboardData?.throttlePos} brakePos={dashboardData?.brakePos} />
          </DashboardItem>
        ) : (
          <Skeleton variant="rectangular" height={426} animation="wave" style={{ borderRadius: 10 }} />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
