import { Skeleton } from "@mui/material";
import { IWheelData } from "models/IWheelData";
import DashboardItem from "components/Layout/TransitionItem";
import Tyre from "./Tyre";

interface Props {
  wheels: IWheelData[] | undefined;
  tyreNameFront: string | undefined;
  tyreNameRear: string | undefined;
}
const Tyres = ({ wheels, tyreNameFront, tyreNameRear }: Props) => {
  return (
    <div className="tyres-container">
      <div className="tyres-container-inner">
        {wheels !== undefined ? (
          <DashboardItem>
            <Tyre data={wheels[0]} />
          </DashboardItem>
        ) : (
          <Skeleton variant="rectangular" height={312} animation="wave" style={{ borderRadius: 10 }} />
        )}
        {wheels !== undefined ? (
          <DashboardItem>
            <Tyre data={wheels[1]} reverse={true} />
          </DashboardItem>
        ) : (
          <Skeleton variant="rectangular" height={312} animation="wave" style={{ borderRadius: 10 }} />
        )}
      </div>
      <div className="tyres-container-inner">
        {wheels !== undefined ? (
          <DashboardItem>
            <Tyre data={wheels[2]} />
          </DashboardItem>
        ) : (
          <Skeleton variant="rectangular" height={312} animation="wave" style={{ borderRadius: 10 }} />
        )}
        {wheels !== undefined ? (
          <DashboardItem>
            <Tyre data={wheels[3]} reverse={true} />
          </DashboardItem>
        ) : (
          <Skeleton variant="rectangular" height={312} animation="wave" style={{ borderRadius: 10 }} />
        )}
      </div>
      {tyreNameFront !== undefined || tyreNameRear !== undefined ? (
        <DashboardItem>
          <div>
            <div className="tyre-name">{`F: ${tyreNameFront}`}</div>
            <div className="tyre-name">{`R: ${tyreNameRear}`}</div>
          </div>
        </DashboardItem>
      ) : (
        <Skeleton variant="rectangular" height={70} animation="wave" style={{ borderRadius: 10 }} />
      )}
    </div>
  );
};

export default Tyres;
