import { Alert, Slide, Snackbar } from "@mui/material";
import { memo, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setShowNotification } from "store/reducers/notificationSlice";

const Notification = () => {
  const { show, message, status } = useAppSelector((state) => state.notifications);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      const timeout = setTimeout(() => {
        dispatch(setShowNotification(false));
      }, 2000);
      return () => {
        clearTimeout(timeout);
        isMounted = false;
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      open={show}
      onClose={() => dispatch(setShowNotification(false))}
      key={"bottomleft"}
      TransitionComponent={Slide}
    >
      <Alert variant="filled" severity={status}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default memo(Notification);
