import { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import CustomTooltip from "./CustomTooltip";
import ProgressBarContainer from "./ProgressBarContainer";

interface Props {
  throttlePos: number | undefined;
  brakePos: number | undefined;
}

const DashboardChart = ({ throttlePos, brakePos }: Props) => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const newData = [...data];
    let newObject = {
      seconds: newData.length / 10,
      throttle: throttlePos,
      brake: brakePos,
    };
    if (newData.length <= 100) {
      newData.push(newObject);
    }
    if (newData.length === 101) {
      newData.shift();
      newData.push(newObject);
    }
    setData(newData);
  }, [throttlePos, brakePos]);

  return (
    <div className="chart-container">
      <div className="progress-bars">
        <ProgressBarContainer name="Throttle" value={throttlePos !== undefined ? throttlePos : 0} up={true} color="#469626" />
        <ProgressBarContainer name="Brake" value={brakePos !== undefined ? brakePos : 0} up={true} color="#fb3c3c" />
      </div>
      <div className="chart">
        <ResponsiveContainer minHeight={400} minWidth="auto">
          <LineChart
            data={data}
            margin={{
              top: 5,
              left: 10,
              bottom: 5,
              right: 15,
            }}
            className="chart-line"
          >
            <XAxis
              tickCount={10}
              interval={9}
              tickFormatter={(tick, index) => {
                return (parseInt(tick) / 10).toString();
              }}
            />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />

            <Line dot={false} isAnimationActive={false} type="monotone" dataKey="throttle" stroke="#fb3c3c" />
            <Line dot={false} isAnimationActive={false} type="monotone" dataKey="brake" stroke="#469626" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default DashboardChart;
