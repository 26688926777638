import Header from "./Header";
import Navigation from "./Navigation";
import "styles/components/layout.css";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";
import Welcome from "pages/Welcome";
import { AnimatePresence } from "framer-motion";
import Notification from "components/Layout/Notification";
import { useNavigate } from "react-router-dom";

interface LayoutProps {
  children: JSX.Element;
}

function Layout({ children }: LayoutProps) {
  const { ip } = useAppSelector((state) => state.socket);
  const { dev, headerShown, user } = useAppSelector((state) => state.auth);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!dev) {
      let accessToken = user != null || user ? user.accessToken : undefined
      if (accessToken) {
        //verifyToken(accessToken);
      }
      if (!accessToken) {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ip === "") {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ip]);

  return (
    <div className="layout" style={{ overflow: ip === "" ? "hidden" : "" }}>
      <Navigation />
      {headerShown ? <Header /> : null}
      <div className="children" style={{ marginTop: headerShown ? 0 : 40 }}>
        {children}
      </div>
      <Notification />
      <AnimatePresence initial={false} exitBeforeEnter={true} onExitComplete={() => null}>
        {showModal && <Welcome />}
      </AnimatePresence>
    </div>
  );
}

export default Layout;
