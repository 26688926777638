import _ from "lodash";
import { useEffect, useState } from "react";
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useSpring, animated, config } from "react-spring";
import "styles/components/speedometer.css";

function RadialSeparator(props) {
  return (
    <div
      style={{
        position: "absolute",
        height: "100%",
        transform: `rotate(${props.turns}turn)`,
      }}
    >
      <div style={props.style}></div>
      <div
        className="counts"
        style={{
          margin:
            parseInt(props.counts[props.value]) <= 80 && parseInt(props.counts[props.value]) > 0
              ? "1px 0 0 -6px"
              : parseInt(props.counts[props.value]) > 80
              ? "1px 0 0 -10px"
              : "1px 0 0 -3px",
        }}
      >
        {props.counts[props.value]}
      </div>
    </div>
  );
}

function RadialSeparators(props) {
  return props.values.map((value, index) => (
    <RadialSeparator counts={props.counts} key={index} turns={value} value={props.values.indexOf(value)} style={props.style} />
  ));
}

export default function Speedometer({ speed, rpm }) {
  const [currentValue, setValue] = useState(0);
  const [prevValue, setPrevValue] = useState(0);

  const props = useSpring({ val: currentValue, from: { val: prevValue } });

  useEffect(() => {
    setPrevValue(currentValue);
    setValue(speed);
  }, [speed]);

  const counts = [0, 20, 40, 60, 80, 100, 120, 140, 160, 180, 200, 220, 240, 260, 280, 300];
  const rpmCounts = [0, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000];

  const strokeWidth = 4;
  const rotation = 0.625;
  const circleRatio = 0.75;
  const numSeparators = rpm ? 10 : 15;

  const lastindex = rpm ? rpmCounts.length - 1 : counts.length - 1;

  return (
    <div className="speedometer-wrp">
      <div className="speedometer__inter"></div>
      <div className="speedometer">
        <CircularProgressbarWithChildren
          value={rpm ? Math.floor((speed / rpmCounts[lastindex]) * 100) - 1 : Math.floor((speed / counts[lastindex]) * 100) - 1}
          strokeWidth={strokeWidth}
          circleRatio={circleRatio}
          styles={buildStyles({
            rotation: rotation,
            pathColor: "#fb923c",
            trailColor: "#fb923c41",
          })}
        >
          <div className="mitter">
            <RadialSeparators
              counts={rpm ? rpmCounts : counts}
              values={_.range(numSeparators + 1).map((index) => (circleRatio / numSeparators) * index + rotation)}
              style={{
                background: "#fb923c41",
                width: "2px",
                height: "7px",
                marginTop: 30,
                marginLeft: 1,
              }}
            />
            <div className="speed__container">
              <animated.div className="speed-percentage">{props.val.to((val) => Math.floor(val))}</animated.div>
              <span className="speed-kmh">{`${rpm ? "RPM" : "km/h"}`}</span>
            </div>
          </div>
        </CircularProgressbarWithChildren>
      </div>
    </div>
  );
}
