import { Rating } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "store/hooks";
import { setFeedbackShown } from "store/reducers/authSlice";

const Feedback = (onClose: any) => {
  const [rating, setRating] = useState<number | null>(0);
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState("");
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const dispatch = useAppDispatch();

  const onSubmit = () => {
    console.log(rating);
    if (rating === 0 || rating === null) {
      setError("Please select a rating");
      return;
    }
    if (message.length === 0) {
      setError("Please enter a message");
      return;
    }
    setError("");
    console.log(rating, message);
  };

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          dispatch(setFeedbackShown(false));
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <motion.div className="backdrop" onClick={onClose}>
      <motion.div ref={wrapperRef} className="feedback" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <div className="feedback-header">
          <div>Help us improve</div>
        </div>
        <div className="feedback-body">
          <div className="rating">
            <div className="rating-header">Rating</div>
            <div className="rating-body">
              <Rating
                sx={{
                  ".MuiRating-icon	": {
                    color: "#f97316",
                  },
                }}
                value={rating}
                onChange={(event, newValue) => {
                  setRating(newValue);
                }}
                name="half-rating"
                defaultValue={0}
                precision={0.5}
                size="large"
              />
            </div>
          </div>
          <div className="feedback-message">
            <div className="rating-header">Message</div>
            <div className="message-body">
              <textarea value={message} onChange={(event) => setMessage(event.target.value)} name="message" id="message"></textarea>
            </div>
          </div>
          <div className="error-message">{error}</div>
          <div className="feedback-footer">
            <button className="feedback-button" onClick={() => onSubmit()}>
              Send
            </button>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Feedback;
