import { Skeleton } from "@mui/material";
import { AirIcon, CloudIcon, PathIcon, RainIcon, TrackIcon, WindIcon } from "helpers/icons";
import { IHeader } from "models/IHeader";
import { SocketEvents } from "models/Sockets";
import { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store/hooks";
import { setTrackName } from "store/reducers/socketSlice";
import "styles/components/header.css";
import TransitionItem from "./TransitionItem";

function Header() {
  const { ip, sockets, trackName } = useAppSelector((state) => state.socket);
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [header, setHeader] = useState<IHeader>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (ip !== "") {
      connect();
    }
  }, [ip]);

  useEffect(() => {
    let isMounted = true;

    if (ws && isMounted) {
      let connectInterval = setTimeout(check, Math.min(1000, 5000));

      ws.onmessage = (event) => {
        if (isMounted) {
          try {
            let header: IHeader = JSON.parse(event.data);
            header.cloud = Math.round(header.cloud * 100);
            header.rain = Math.round(header.rain * 100);
            header.minPathWetness = Math.round(header.minPathWetness * 100);
            header.maxPathWetness = Math.round(header.maxPathWetness * 100);

            if (trackName === "") dispatch(setTrackName(header.trackname));
            setHeader(header);
          } catch (err) {
            console.log("Error: ", err);
          }
        }
      };
      ws.onclose = (e) => {
        console.log(e.reason);

        let timeout = 5000;
        connectInterval = setTimeout(check, Math.min(10000, timeout));
      };

      ws.onerror = (err) => {
        console.error(err);

        ws.close();
      };
    }
  }, [ws]);

  const connect = () => {
    const portNumber = sockets.find((object) => object.name === SocketEvents.HEADER)?.port;
    if (ws === null) {
      setWs(new WebSocket(`ws://${ip}:${portNumber}`));
    }
  };

  const check = () => {
    if (!ws || ws.readyState === WebSocket.CLOSED) connect(); //check if websocket instance is closed, if so call `connect` function.
  };

  return (
    <>
      {header ? (
        <>
          <div className="header">
            <TransitionItem className="header__box header__box-laps">
              <div className="header__box-laps">
                <div className="title">{header?.session}</div>
                <div className="laps">
                  <div className="lap">Lap</div>
                  <div className="lap__count">{header?.lap}</div>
                </div>
              </div>
            </TransitionItem>
            <TransitionItem className="header__box header__box-position">
              <div className="header__box-position">
                <div className="title">Sectors</div>
                <div className="sectors">
                  <div className={`${header?.sector1Flag === 0 ? "sector" : "sector sector-active"}`}>S1</div>
                  <div className={`${header?.sector2Flag === 0 ? "sector" : "sector sector-active"}`}>S2</div>
                  <div className={`${header?.sector3Flag === 0 ? "sector" : "sector sector-active"}`}>S3</div>
                </div>
              </div>
            </TransitionItem>

            <TransitionItem className="header__box header__box-timer">
              <div className="timer">{header?.timeLeft}</div>
            </TransitionItem>
            <TransitionItem className="header__box">
              <div className="header__box-position">
                <div className="position__title">Positon</div>
                <div className="position">
                  <div className="current__position">{header?.place}</div>
                  <div className="position__divider">/</div>
                  <div className="total__positions">{header?.numVehicles}</div>
                </div>
              </div>
            </TransitionItem>
            <TransitionItem className="header__box header__box-weather">
              <div className="weather">
                <div className="weather__item">
                  <div className="weather__icon">
                    <img alt="track" height={20} src={TrackIcon} />
                    <div className="weather__text">Track:</div>
                  </div>
                  <div className="weather__value">{header?.trackTemp.toFixed(1)}°</div>
                </div>
                <div className="weather__item">
                  <div className="weather__icon">
                    <img alt="rain" height={20} src={RainIcon} />
                    <div className="weather__text">Rain:</div>
                  </div>
                  <div className="weather__value">{header?.rain}%</div>
                </div>
                <div className="weather__item">
                  <div className="weather__icon">
                    <img alt="air" height={20} src={AirIcon} />
                    <div className="weather__text">Air:</div>
                  </div>
                  <div className="weather__value">{header?.ambientTemp.toFixed(1)}°</div>
                </div>
                <div className="weather__item">
                  <div className="weather__icon">
                    <img alt="cloud" height={20} src={CloudIcon} />
                    <div className="weather__text">Cloud:</div>
                  </div>
                  <div className="weather__value">{header?.cloud}%</div>
                </div>
                <div className="weather__item">
                  <div className="weather__icon">
                    <img alt="wind" height={20} src={WindIcon} />
                    <div className="weather__text">Wind:</div>
                  </div>
                  <div className="weather__value">{header?.wind.toFixed(1)} m/s</div>
                </div>
                <div className="weather__item">
                  <div className="weather__icon">
                    <img alt="path" height={20} src={PathIcon} />
                    <div className="weather__text">Path:</div>
                  </div>
                  <div className="weather__value">
                    {header?.minPathWetness} -- {header?.maxPathWetness}%
                  </div>
                </div>
              </div>
            </TransitionItem>
          </div>
          {/*<div className="header-divider"></div>*/}
        </>
      ) : (
        <div className="header">
          <Skeleton variant="rectangular" height={150} style={{ borderRadius: 8 }} />
          <Skeleton variant="rectangular" height={150} style={{ borderRadius: 8 }} />
          <Skeleton variant="rectangular" height={150} style={{ borderRadius: 8 }} />
          <Skeleton variant="rectangular" height={150} style={{ borderRadius: 8 }} />
          <Skeleton variant="rectangular" height={150} style={{ borderRadius: 8 }} />
        </div>
      )}
    </>
  );
}

export default memo(Header);
